import { Routes, Route } from 'react-router-dom';

import Layout from './pages/Layout/Layout';
import NotAuthLayout from './pages/Layout/NotAuthLayout';
import NotFoundPage from './pages/Layout/NotFoundPage';

import Login from './pages/Login';
import Apps from './pages/Apps/Apps';
import Campaigns from './pages/Campaigns/Campaigns';
import CreateCampaign from './pages/Campaigns/CreateCampaign';
import Sets from './pages/Sets/Sets';

const ROUTES = [
  {
    path: 'apps',
    element: <Apps />,
  },
  {
    path: 'apps/:app_id/campaigns',
    element: <Campaigns />,
  },
  {
    path: 'apps/:app_id/campaigns/new',
    element: <CreateCampaign />,
  },
  {
    path: 'apps/:app_id/campaigns/:campaign_id/adsets',
    element: <Sets />,
  },
  {
    path: 'apps/:app_id/campaigns/:campaign_id/edit',
    element: <Sets />,
  },
  {
    path: 'apps/:app_id/campaigns/:campaign_id/add_adset',
    element: <Sets />,
  },
];

export const RoutesConfig = () => {
  return (
    <Routes>
      <Route element={<NotAuthLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
      </Route>

      <Route>
        <Route path="/" element={<Layout />}>
          {ROUTES.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
