import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import COUINTRIES from '../../utils/ISO3166-1.alpha2';

const options = Object.keys(COUINTRIES)?.map((key) => ({
  code: key,
  label: COUINTRIES[key],
}));

export default function CountriesSelect({ required, selectedCountries, onChange }) {
  const handleChange = (countries) => {
    onChange({ name: 'countries', value: countries.map((i) => i.code) });
  };

  return (
    <>
      <Stack spacing={3}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          value={options.filter((value) =>
            selectedCountries.includes(value.code)
          )}
          onChange={(event, countries) => {
            handleChange(countries);
          }}
          options={options}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              
              label="Countries *"
              name="countries"
              value={params.value}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
      </Stack>
    </>
  );
}
