import { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';

import {
  DEFAULT_PAGINATION,
  adsetsHeaderCells,
  CAMPAIGN_STATUS,
} from '../../utils/const';
import Modals from './Modals';
import Amount from '../components/AmountBadge';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    onEditClick,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>

        {adsetsHeaderCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.align
                ? headCell.align
                : headCell.numeric
                ? 'right'
                : 'left'
            }
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>

            {headCell.id === 'bid' && (
              <Tooltip title="Choose more than one row" placement="top">
                <IconButton onClick={onEditClick} disabled={numSelected < 1}>
                  <EditIcon color="secondary" />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

const initialModalParams = {
  type: '',
  open: false,
  campaign: {
    id: undefined,
    daily_budget: '',
  },
};

export default function SetsList({ rows, setRows }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('keyword');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGINATION.PER_PAGE);
  const [editModal, setEditModal] = useState(initialModalParams);
  const [searchValue, setSearch] = useState('');
  const [initialRows, setInitialRows] = useState([]);

  useEffect(() => {
    setInitialRows(rows);
  }, []);

  const openBidManagment = (event) => {
    event.stopPropagation();
    setEditModal({ type: 'bidManagment', open: true, selected });
  };

  const handleCloseEdit = () => {
    console.log('handleCloseEdit');
    setEditModal(initialModalParams);
  };

  const handlePlay = (event, campaignId) => {
    event.stopPropagation();
    const { name, checked } = event.target;
    console.log('кампания', campaignId);
    console.log('статус', {
      [name]: checked ? CAMPAIGN_STATUS.ENABLED : CAMPAIGN_STATUS.PAUSED,
    });
    // setForm({
    //   ...form,
    //   [name]: checked ? CAMPAIGN_STATUS.ENABLED : CAMPAIGN_STATUS.PAUSED,
    // });
  };

  const handleDelete = (event, deleteType, campaignId) => {
    event.stopPropagation();
    setEditModal({ open: true, type: 'delete', deleteType, campaignId });
  };

  const handleConfirmDelete = (deleteType, campaignId) => {
    if (deleteType === 'single') {
      console.log('delete request single', campaignId);
    } else {
      console.log('delete request bulk', selected);
    }

    // if (success) {
    //   setSelected([]);
    //   setRows([]);
    //   handleCloseEdit();
    // }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event?.target?.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (campaignId) => {};

  const handleSelect = (event, name) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSearch = (event) => {
    const { value } = event.target;

    if (value === '') {
      handleResetSearch();
    } else {
      setSearch(value);
      debounceSearch(value);
    }
  };

  const getSearch = (value) => {
    const filteredRows = initialRows.filter((row) => {
      return row.keyword?.toLowerCase().includes(value?.toLowerCase());
    });

    setRows(filteredRows);
  };

  const debounceSearch = useCallback(debounce(getSearch, 500), []);

  const handleResetSearch = () => {
    setSearch('');
    setRows(initialRows);
  };

  return (
    <>
      <Box sx={{ width: '100%', my: 5 }}>
        <Paper sx={{ width: '100%' }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            <FormControl variant="outlined" sx={{ my: 2 }}>
              <InputLabel>Search</InputLabel>
              <OutlinedInput
                sx={{ width: '400px' }}
                label="Search"
                value={searchValue}
                onChange={handleSearch}
                // onChange={debouncedChangeHandler}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleResetSearch}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {searchValue ? <CloseIcon fontSize="small" /> : ''}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {selected.length > 0 && (
              <>
                <Typography
                  sx={{ ml: 2, mr: 'auto' }}
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                >
                  {selected.length} selected
                </Typography>
                <Tooltip title="Delete" placement="top-end">
                  <IconButton onClick={(e) => handleDelete(e, 'bulk')}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Toolbar>

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              stickyHeader
              aria-label="sticky table"
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                onEditClick={openBidManagment}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    const {
                      id,
                      keyword,
                      status,
                      avg_cpa,
                      avg_cpt,
                      avg_cpm,
                      impressions,
                      installs,
                      spend,
                      taps,
                      ttr,
                      bid,
                    } = row;
                    return (
                      <TableRow
                        hover
                        onClick={() => handleClick(row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            onChange={(event) => handleSelect(event, row.id)}
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {keyword}
                        </TableCell>
                        <TableCell align="center">{avg_cpa}</TableCell>
                        <TableCell align="center">{avg_cpt}</TableCell>
                        <TableCell align="center">{avg_cpm}</TableCell>
                        <TableCell align="center">{impressions}</TableCell>
                        <TableCell align="center">{installs}</TableCell>
                        <TableCell align="center">{spend}</TableCell>
                        <TableCell align="center">{taps}</TableCell>
                        <TableCell align="center">{ttr}</TableCell>
                        <TableCell align="center">
                          {bid && <Amount value={bid} />}
                        </TableCell>

                        <TableCell
                          sx={{
                            width: 200,
                          }}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() =>
                              setEditModal({
                                type: 'bidManagment',
                                open: true,
                                bid,
                              })
                            }
                          >
                            Edit CPT bid
                          </Button>
                          <Box>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography>{CAMPAIGN_STATUS.PAUSED}</Typography>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={status === CAMPAIGN_STATUS.ENABLED}
                                    onChange={(e) => handlePlay(e, row.id)}
                                    name="status"
                                  />
                                }
                              />
                              <Typography>{CAMPAIGN_STATUS.ENABLED}</Typography>
                            </Stack>
                          </Box>
                          {/* <Button
                          onClick={handlePlay}
                          sx={{
                            my: 2,
                          }}
                          size="small"
                          variant="outlined"
                        >
                          Pause/Start
                        </Button> */}
                          <Button
                            onClick={(e) => handleDelete(e, 'single', id)}
                            size="small"
                            variant="outlined"
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={DEFAULT_PAGINATION.PER_PAGE_OPTIONS}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
      {editModal.open && (
        <Modals
          editModal={editModal}
          handleClose={handleCloseEdit}
          handleConfirm={handleConfirmDelete}
        />
      )}
    </>
  );
}
