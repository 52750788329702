import { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import CreateClient from '../Clients/CreateClient';
import ClientsSelect from '../Clients/ClientsSelect';
import ThemeSwitcher from '../components/ThemeSwitcher';

import { useAuth } from '../../hooks/useAuth';

const initialForm = {
  name: '',
  privateKey: '',
  teamId: '',
  clientId: '',
  keyId: '',
  currency: '',
};
export default function Menu() {
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { logout, clients, setClients } = useAuth();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setForm(initialForm);
    setOpen(false);
  };

  const [form, setForm] = useState(initialForm);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  return (
    <>
      <ThemeSwitcher />
      <ClientsSelect />
      <Button variant="contained" onClick={handleOpen} sx={{ mr: 2 }}>
        Add new customer
      </Button>
      {/* token {token} */}
      {/* selectedUser {selectedUser && selectedUser.name} */}
      <Button variant="contained" onClick={logout}>
        Logout
      </Button>
      <CreateClient
        open={open}
        handleClose={handleClose}
        handleChange={handleChange}
        form={form}
        isLoading={isLoading}
        setLoading={setLoading}
        clients={clients}
        setClients={setClients}
      />
    </>
  );
}
