import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SetsList from './SetsList';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

import Modals from './Modals';
// import { getSets } from '../../utils/methods';
import { useAuth } from '../../hooks/useAuth';
import { useSets } from '../../hooks/useSets';

const initialModalParams = {
  type: '',
  open: false,
};

export default function Sets() {
  const [editModal, setEditModal] = useState(initialModalParams);

  const {
    sets,
    setSets,
    getSets,
    isLoading,
    campaignName,
    negativeKeywords,
  } = useSets();

  const handleOpen = (type) => {
    if (type === 'editNegativeKeywords') {
      setEditModal({ type, open: true, negativeKeywords: negativeKeywords });
    } else {
      setEditModal({ type, open: true });
    }
  };

  const handleCloseEdit = () => {
    setEditModal(initialModalParams);
  };

  useEffect(() => {
    getSets();
  }, []);

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width={170} height={32} />
      ) : (
        <Typography variant="h4" component="div">
          {campaignName}
        </Typography>
      )}

      <Button
        onClick={() => handleOpen('addKeywords')}
        variant="outlined"
        sx={{ mt: 2, mr: 1 }}
      >
        Add adset
      </Button>
      <Button
        onClick={() => handleOpen('editNegativeKeywords')}
        variant="outlined"
        sx={{ mt: 2 }}
      >
        Edit negative keywords
      </Button>
      {isLoading ? (
        <CircularProgress color="info" size={60} />
      ) : (
        <>
          <SetsList rows={sets} setRows={setSets} />
          {editModal.open && (
            <Modals editModal={editModal} handleClose={handleCloseEdit} />
          )}
        </>
      )}
    </>
  );
}
