import axios from './API';
import { objectToSnakeCase } from './const';

export const getClients = () => axios.get('clients');
export const createClient = (form) =>
  axios.post('clients', objectToSnakeCase(form));

export const getApps = (client_id) => axios.get(`clients/${client_id}/apps`);
export const syncApps = (client_id) =>
  axios.post(`clients/${client_id}/apps/sync`);

export const getCampaigns = (client_id, app_id) =>
  axios.get(`clients/${client_id}/apps/${app_id}/campaigns`);

  export const createCampaign = (client_id, app_id, form) =>
  axios.post(
    `clients/${client_id}/apps/${app_id}/campaigns`,
    objectToSnakeCase(form)
  );

export const editCampaign = (client_id, app_id, campaigns_id, form) =>
  axios.put(
    `clients/${client_id}/apps/${app_id}/campaigns/${campaigns_id}`,
    objectToSnakeCase(form)
  );

export const deleteCampaign = (client_id, app_id, campaigns_id) =>
  axios.delete(`clients/${client_id}/apps/${app_id}/campaigns/${campaigns_id}`);

export const getOrgs = (client_id) => axios.get(`clients/${client_id}/orgs`);

export const getSets = (client_id, app_id, campaign_id) =>
  axios.get(
    `clients/${client_id}/apps/${app_id}/campaigns/${campaign_id}/adsets`
  );

export const createSets = (client_id, app_id, campaign_id, form) =>
  axios.post(
    `clients/${client_id}/apps/${app_id}/campaigns/${campaign_id}/adsets`,
    objectToSnakeCase(form)
  );
export const deleteSets = (client_id, app_id, campaign_id) =>
  axios.delete(
    `clients/${client_id}/apps/${app_id}/campaigns/${campaign_id}/adsets`
  );

export const editNegativeKeys = (client_id, app_id, campaign_id, form) =>
  axios.put(
    `clients/${client_id}/apps/${app_id}/campaigns/${campaign_id}/negative`,
    objectToSnakeCase(form)
  );
