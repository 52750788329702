import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { CURRENCY_SYMBOLS } from '../../utils/const';
import { useAuth } from '../../hooks/useAuth';
import { useSets } from '../../hooks/useSets';

const initialForm = {
  keywords: '',
  keywordsCptBid: '',
};

export default function AddKeys({ editModal, handleClose }) {
  const { open } = editModal;

  const { addSet } = useSets();
  const { selectedUser } = useAuth();

  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState(initialForm);
  const { keywords, keywordsCptBid } = form;

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setLoading(true);

    addSet(
      {
        keywords: keywords.split(/\r?\n/).filter((i) => i),
        keywordsCptBid,
      },
      handleClose
    );
    setLoading(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>Add adsets</DialogTitle>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleEdit}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            required
            rows={4}
            label="Keywords"
            multiline
            placeholder="Maximum 20 keywords"
            name="keywords"
            value={keywords}
            onChange={(e) => handleChange(e, true)}
          />
          <TextField
            required
            value={keywordsCptBid}
            onChange={handleChange}
            name="keywordsCptBid"
            label={`${
              selectedUser.currency
                ? CURRENCY_SYMBOLS[selectedUser.currency]
                : '$'
            } Keywords CPT Bid`}
            placeholder="00.00"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            pb: 4,
            mx: 2,
          }}
        >
          <Button
            onClick={() => {
              setForm(initialForm);
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            autoFocus
          >
            Add
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
