import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useAuth } from '../../hooks/useAuth';
import { getOrgs } from '../../utils/methods';

export default function OrgSelect({ required, orgId, onChange }) {
  const [orgs, setOrgs] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const { selectedUser } = useAuth();

  useEffect(() => {
    getOrgs(selectedUser?.id)
      .then((res) => {
        setOrgs(res.data);
      })
      .finally((e) => {
        setLoading(false);
      });
  }, []);

  const handleChange = (value) => {
    onChange({ name: 'orgId', value: value?.id || null });
  };

  return (
    <>
      <Autocomplete
        disabled={isLoading}
        value={orgs?.find((value) => value.id === orgId)}
        onChange={(event, org) => {
          handleChange(org);
        }}
        options={orgs}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            
            label={isLoading ? 'Organizations are loading...' : 'Organizations'}
            name="orgId"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
      />
    </>
  );
}
