import { createContext, useContext } from 'react';

import { ThemeProvider as Provider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import { useLocalStorage } from './useLocalStorage';

const commonTheme = {
  typography: {
    error: {
      color: 'red',
      fontSize: '0.9em',
    },
  },
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation3: {
          boxShadow:
            'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        margin: 'normal',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
  },
};

const LightThemeStyles = createTheme({
  palette: {
    type: 'light',
    mode: 'light',
    secondary: {
      main: '#708090',
      contrastText: '#fff',
    },
  },
  ...commonTheme,
});

const DarkThemeStyles = createTheme({
  palette: {
    type: 'dark',
    mode: 'dark',
    primary: {
      main: '#7169DC',
    },
    secondary: {
      main: '#708090',
    },
  },
  ...commonTheme,
});

const themes = {
  light: LightThemeStyles,
  dark: DarkThemeStyles,
};

const ThemeContext = createContext({});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useLocalStorage('mode', 'light');

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      <Provider theme={themes[theme]}>
        <CssBaseline />
        <ToastContainer theme={theme} />

        {children}
      </Provider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
