import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getSets as getList,
  createSets,
  editNegativeKeys,
} from '../utils/methods';
import { useAuth } from './useAuth';
import { toast } from 'react-toastify';

const SetsContext = createContext({});

export const SetsProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [appName, setAppName] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [sets, setSets] = useState([]);
  const [negativeKeywords, setNegativeKeywords] = useState('');

  const { selectedUser } = useAuth();
  const { app_id, campaign_id } = useParams();

  const getSets = () => {
    setLoading(true);
    getList(selectedUser?.id, app_id, campaign_id)
      .then((res) => {
        setCampaignName(res?.data?.campaign_name);
        setAppName(res?.data?.application_name);
        setNegativeKeywords(res?.data?.negative_keywords);
        setSets(res?.data?.adsets);
      })
      .finally(() => setLoading(false));
  };

  const addSet = (data, action) => {
    createSets(selectedUser?.id, app_id, campaign_id, data).then(() => {
      getSets();
      toast.success('Added success');
      action();
    });
  };

  const editNegativeList = (negativeKeywords, action) => {
    editNegativeKeys(selectedUser?.id, app_id, campaign_id, {
      negativeKeywords,
    }).then(() => {
      getSets();
      toast.success('Updated success');
      action();
    });
  };

  const editSet = (setId, data) => {};

  const deleteSet = (setId) => {};

  return (
    <SetsContext.Provider
      value={{
        isLoading,
        appName,
        campaignName,
        negativeKeywords,
        editNegativeList,
        sets,
        getSets,
        setSets,
        editSet,
        addSet,
        deleteSet,
      }}
    >
      {children}
    </SetsContext.Provider>
  );
};

export const useSets = () => {
  return useContext(SetsContext);
};
