import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import { HOME_ROUTE } from '../../utils/const';

import { useAuth } from '../../hooks/useAuth';

export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = useState(null);

  const { clients, isLoading, selectedUser, setSelectedUser } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, client) => {
    setSelectedUser(client);
    setAnchorEl(null);
    if (pathname !== HOME_ROUTE) {
      navigate(HOME_ROUTE);
    }
  };

  return (
    !isLoading && (
      <>
        <Tooltip title="Change user" placement="top-end" arrow>
          <Button
            sx={{ mr: 2 }}
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {selectedUser?.name}
          </Button>
        </Tooltip>
        {clients && (
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {clients?.map((i) => (
              <MenuItem
                key={i.id}
                selected={i.id === selectedUser?.id}
                onClick={(event) => handleMenuItemClick(event, i)}
              >
                {i.name}
              </MenuItem>
            ))}
          </Menu>
        )}
      </>
    )
  );
}
