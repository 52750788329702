import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';

import { createClient } from '../../utils/methods';
import { CURRENCIES } from '../../utils/const';

export default function CreateClient({
  open,
  handleClose,
  handleChange,
  form,
  isLoading,
  setLoading,
  clients,
  setClients,
}) {
  const { name, privateKey, clientId, teamId, keyId, currency } = form;

  const handleAddCustomer = (e) => {
    e.preventDefault();

    createClient(form)
      .then((res) => {
        const {
          data: { id, name },
        } = res;
        setClients([...clients, { id, name }]);
        toast.success('Created success');
        handleClose();
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>Add new customer</DialogTitle>
      <Box component="form" autoComplete="off" onSubmit={handleAddCustomer}>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            value={name}
            onChange={handleChange}
            label="name"
            name="name"
          />
          <TextField
            value={privateKey}
            onChange={handleChange}
            name="privateKey"
            label="privateKey"
          />
          <TextField
            value={teamId}
            onChange={handleChange}
            name="teamId"
            label="teamId"
          />
          <TextField
            value={clientId}
            onChange={handleChange}
            name="clientId"
            label="clientId"
          />
          <TextField
            value={keyId}
            onChange={handleChange}
            name="keyId"
            label="keyId"
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Currency</InputLabel>
            <Select
              value={currency}
              label="Currency"
              name="currency"
              onChange={handleChange}
            >
              {CURRENCIES.map((i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            pb: 4,
            mx: 2,
          }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            autoFocus
          >
            Add
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
