import { Navigate, Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import { CampaignsProvider } from '../../hooks/useCampaigns';
import { SetsProvider } from '../../hooks/useSets';

import Menu from './Menu';
import Breadcrumbs from './Breadcrumbs';
import { useAuth } from '../../hooks/useAuth';

export default function Layout() {
  const { signed, isLoading } = useAuth();

  if (!signed) {
    return <Navigate to="/login" />;
  }

  return (
    <CampaignsProvider>
      <SetsProvider>
        <Box
          component="header"
          sx={{
            width: '100%',
            py: 3,
            background: '#01366b',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              maxWidth: 'xl',
              mx: 'auto',
            }}
          >
            {isLoading ? <CircularProgress /> : <Menu />}
          </Box>
        </Box>

        <Container component="main" maxWidth="xl" sx={{ mt: 4 }}>
          {!isLoading && <Breadcrumbs />}
          {isLoading ? <CircularProgress /> : <Outlet />}
        </Container>
      </SetsProvider>
    </CampaignsProvider>
  );
}
