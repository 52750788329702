import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import CountriesSelect from '../components/CountriesSelect';
import OrgsSelect from '../components/OrgsSelect';

import { useNavigate, useParams } from 'react-router-dom';
import { CAMPAIGN_STATUS, CURRENCY_SYMBOLS } from '../../utils/const';
import { createCampaign } from '../../utils/methods';

import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';
import { useCampaigns } from '../../hooks/useCampaigns';

export default function CreateCampaignForm() {
  let { app_id } = useParams();

  const initialForm = {
    name: '',
    orgId: null,
    countries: [],
    dailyBudget: '',
    status: CAMPAIGN_STATUS.ENABLED,
    keywordsCptBid: '',
    keywords: '',
    negativeKeywords: '',
  };

  const [isLoading, setLoading] = useState(false);
  const [errors, setError] = useState({});
  const [form, setForm] = useState(initialForm);

  const {
    name,
    orgId,
    countries,
    dailyBudget,
    status,
    keywordsCptBid,
    keywords,
    negativeKeywords,
  } = form;

  const navigate = useNavigate();
  const { selectedUser } = useAuth();
  const { isDuplicateName, campaigns, getCampaigns } = useCampaigns();

  useEffect(() => {
    if (!campaigns.length) {
      getCampaigns();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError({});

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSelectChange = ({ name, value }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setForm({
      ...form,
      [name]: checked ? CAMPAIGN_STATUS.ENABLED : CAMPAIGN_STATUS.PAUSED,
    });
  };

  const handleCreateCampaign = (e) => {
    e.preventDefault();

    if (isDuplicateName(form.name)) {
      setError({ name: 'Duplicate name' });
      return false;
    }

    setLoading(true);
    createCampaign(selectedUser?.id, app_id, {
      ...form,
      keywords: keywords.split(/\r?\n/).filter((i) => i),
      negativeKeywords: negativeKeywords.split(/\r?\n/).filter((i) => i),
    })
      .then((res) => {
        toast.success('Created success');
        navigate(-1);
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Typography variant="h4" component="div">
        New Campaign
      </Typography>

      {/* <form autoComplete="off" onSubmit={handleCreateCampaign}> */}
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleCreateCampaign}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 500,
          mt: 1,
          mb: 6,
        }}
      >
        <OrgsSelect
          required
          selectedCountries={orgId}
          onChange={handleSelectChange}
        />
        <TextField
          required
          value={name}
          onChange={handleChange}
          label="Campaign name"
          autoComplete="new-password"
          name="name"
          error={!!errors.name}
          helperText={errors.name}
        />

        <CountriesSelect
          required
          selectedCountries={countries}
          onChange={handleSelectChange}
        />
        {/* <FormControl sx={{ my: 3 }}>
          <InputLabel>Daily budget</InputLabel>
          <OutlinedInput
            value={dailyBudget}
            onChange={handleChange}
            label="Daily budget"
            name="dailyBudget"
            placeholder="00.00"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            startAdornment={
              <InputAdornment position="start">
                {selectedUser.currency
                  ? CURRENCY_SYMBOLS[selectedUser.currency]
                  : '$'}
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl sx={{ mb: 3 }}>
          <InputLabel>Keywords CPT Bid</InputLabel>
          <OutlinedInput
            value={keywordsCptBid}
            onChange={handleChange}
            label="Keywords CPT Bid"
            placeholder="00.00"
            name="keywordsCptBid"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            startAdornment={
              <InputAdornment position="start">
                {selectedUser.currency
                  ? CURRENCY_SYMBOLS[selectedUser.currency]
                  : '$'}
              </InputAdornment>
            }
          />
        </FormControl> */}

        <TextField
          required
          value={dailyBudget}
          onChange={handleChange}
          label={`${
            selectedUser.currency
              ? CURRENCY_SYMBOLS[selectedUser.currency]
              : '$'
          } Daily budget`}
          name="dailyBudget"
          placeholder="00.00"
          type="number"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
        <TextField
          required
          value={keywordsCptBid}
          onChange={handleChange}
          name="keywordsCptBid"
          label={`${
            selectedUser.currency
              ? CURRENCY_SYMBOLS[selectedUser.currency]
              : '$'
          } Keywords CPT Bid`}
          placeholder="00.00"
          type="number"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        />
        <div>
          Status
          <FormControlLabel
            control={
              <Switch
                sx={{ ml: 1 }}
                checked={status === CAMPAIGN_STATUS.ENABLED}
                onChange={handleSwitchChange}
                name="status"
              />
            }
          />
        </div>
        <TextField
          rows={7}
          label="Keywords"
          multiline
          name="keywords"
          placeholder="Maximum 20 keywords"
          value={keywords}
          onChange={handleChange}
        />
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HelpOutlineOutlinedIcon
            sx={{ mr: 1, fontSize: 18 }}
            color="secondary"
          />
          New line new word, one keyword can consist of several words
        </Typography>
        <TextField
          rows={7}
          label="Negative keywords"
          multiline
          name="negativeKeywords"
          placeholder="Maximum 20 keywords"
          value={negativeKeywords}
          onChange={handleChange}
        />
        <Typography
          variant="body2"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HelpOutlineOutlinedIcon
            sx={{ mr: 1, fontSize: 18 }}
            color="secondary"
          />
          New line new word, one keyword can consist of several words
        </Typography>
        <Button
          type="submit"
          size="large"
          disabled={isLoading}
          variant="contained"
          autoFocus
          sx={{ mt: 2 }}
        >
          Create campaign
        </Button>
      </Box>
      {/* </form> */}
    </>
  );
}
