function getEnvVariable(name) {
  const valueFromWindow = window[name];
  if (
    valueFromWindow &&
    valueFromWindow !== '' &&
    !/\$\{/.test(valueFromWindow)
  ) {
    return valueFromWindow;
  } else {
    return process.env[name];
  }
}
export const API_ENDPOINT = getEnvVariable('REACT_APP_API_ENDPOINT');
