import Chip from '@mui/material/Chip';
import { useAuth } from '../../hooks/useAuth';
import { CURRENCY_SYMBOLS } from '../../utils/const';

export default function Amount({ value }) {
  const { selectedUser } = useAuth();

  return (
    <Chip
      label={`${
        selectedUser.currency ? CURRENCY_SYMBOLS[selectedUser.currency] : ''
      } ${value}`}
      color="secondary"
    />
  );
}
