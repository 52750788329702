import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getCampaigns as getList,
  editCampaign as edit,
  deleteCampaign as deleteItem,
} from '../utils/methods';
import { useAuth } from './useAuth';
import { toast } from 'react-toastify';

const CampaignsContext = createContext({});

export const CampaignsProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [appName, setAppName] = useState('');
  const [campaigns, setCampaigns] = useState([]);

  const { selectedUser } = useAuth();
  const { app_id } = useParams();

  const getCampaigns = () => {
    setLoading(true);
    getList(selectedUser?.id, app_id)
      .then((res) => {
        setAppName(res?.data?.application_name);
        setCampaigns(res?.data?.campaigns);
      })
      .finally(() => setLoading(false));
  };

  const editCampaign = (campaignId, data) => {
    setLoading(true);
    edit(selectedUser?.id, app_id, campaignId, data)
      .then((res) => {
        toast.success('Updated success');
        getCampaigns();
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const deleteCampaign = (campaignId) => {
    deleteItem(selectedUser?.id, app_id, campaignId)
      .then((res) => {
        toast.success('Deleted success');
        getCampaigns();
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const isDuplicateName = (name) => {
    return !!campaigns?.find((i) => i.asa_name === name);
  };

  return (
    <CampaignsContext.Provider
      value={{
        isLoading,
        appName,
        campaigns,
        getCampaigns,
        setCampaigns,
        editCampaign,
        isDuplicateName,
        deleteCampaign,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

export const useCampaigns = () => {
  return useContext(CampaignsContext);
};
