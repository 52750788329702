export const HOME_ROUTE = '/apps';

export const PATHS = [
  { name: 'Apps', link: '/apps' },
  { name: 'List', link: '/list' },
  { name: 'Campaign', link: '/campaign' },
];

export const toSnakeCase = function (value) {
  try {
    var upperChars = value.match(/([A-Z])/g);

    if (!upperChars) {
      return value;
    }
  } catch {
    return undefined;
  }

  var str = value.toString();
  for (var i = 0, n = upperChars.length; i < n; i++) {
    str = str.replace(
      new RegExp(upperChars[i]),
      '_' + upperChars[i].toLowerCase()
    );
  }

  if (str.slice(0, 1) === '_') {
    str = str.slice(1);
  }

  return str;
};

export const objectToSnakeCase = (object) => {
  let newObject = {};
  for (let camel in object) {
    newObject[toSnakeCase(camel)] = object[camel];
  }
  return newObject;
};

export const DEFAULT_PAGINATION = {
  PER_PAGE: 200,
  PER_PAGE_OPTIONS: [10, 25, 50, 100, 200, 1000],
};

export const headCells = [
  {
    id: 'asa_name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'daily_budget',
    disablePadding: false,
    label: 'Daily budget',
  },
  {
    id: 'avg_cpa',
    numeric: true,
    disablePadding: false,
    label: 'AVG, CPA',
    align: 'center',
  },
  {
    id: 'avg_cpt',
    numeric: true,
    disablePadding: false,
    label: 'AVG, CPT',
  },
  {
    id: 'impressions',
    numeric: true,
    disablePadding: false,
    label: 'Impressions',
  },
  {
    id: 'installs',
    disablePadding: false,
    label: 'Installs',
  },
  {
    id: 'spend',
    disablePadding: false,
    label: 'Spend',
  },
  {
    id: 'taps',
    numeric: true,
    disablePadding: false,
    label: 'Taps',
  },
  {
    id: 'ttr',
    disablePadding: false,
    label: 'TTR',
  },
  {
    id: 'actions',
    disablePadding: false,
    align: 'center',
    label: 'Actions',
    width: 20,
  },
];


export const adsetsHeaderCells = [
  {
    id: 'keyword',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'avg_cpa',
    numeric: true,
    disablePadding: false,
    label: 'AVG, CPA',
    align: 'center',
  },
  {
    id: 'avg_cpt',
    numeric: true,
    disablePadding: false,
    label: 'AVG, CPT',
    align: 'center',
  },
  {
    id: 'avg_cpm',
    disablePadding: false,
    label: 'AVG, CPM',
    align: 'center',
  },
  {
    id: 'impressions',
    numeric: true,
    disablePadding: false,
    label: 'Impressions',
    align: 'center',
  },
  {
    id: 'installs',
    disablePadding: false,
    label: 'Installs',
    align: 'center',
  },
  {
    id: 'spend',
    disablePadding: false,
    label: 'Spend',
    align: 'center',
  },
  {
    id: 'taps',
    numeric: true,
    disablePadding: false,
    label: 'Taps',
    align: 'center',
  },
  {
    id: 'ttr',
    disablePadding: false,
    label: 'TTR',
    align: 'center',
  },
  {
    id: 'bid',
    disablePadding: false,
    label: 'Bid',
    align: 'center',
  },
  {
    id: 'actions',
    disablePadding: false,
    align: 'center',
    label: 'Actions',
    width: 20,
  },
];

export const CAMPAIGN_STATUS = {
  ENABLED: 'ENABLED',
  PAUSED: 'PAUSED',
};

export const CURRENCIES = ['USD', 'EUR'];
export const CURRENCY_SYMBOLS = {
  USD: '$',
  EUR: '€',
};
