import { AuthProvider } from './hooks/useAuth';
import { AxiosInterceptor } from './utils/API';
import { ThemeProvider } from './hooks/useTheme';
import { RoutesConfig } from './RoutesConfig';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <AxiosInterceptor>
          <RoutesConfig />
        </AxiosInterceptor>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
