import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';

import CountriesSelect from '../components/CountriesSelect';
import OrgsSelect from '../components/OrgsSelect';

import { useNavigate, useParams } from 'react-router-dom';
import { CAMPAIGN_STATUS } from '../../utils/const';
import { editCampaign } from '../../utils/methods';

import { toast } from 'react-toastify';
import { useCampaigns } from '../../hooks/useCampaigns';

const TITLES = {
  dailyBudget: 'Edit daily budget',
};
export default function EditCampaign({ editModal, handleClose }) {
  const { type, open, campaign } = editModal;

  const [form, setForm] = useState({ daily_budget: campaign.daily_budget });
  const { daily_budget } = form;

  // const [isLoading, setLoading] = useState(false);

  const { editCampaign } = useCampaigns();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();
    editCampaign(campaign.id, form);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>{TITLES[type]}</DialogTitle>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleEdit}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            
            value={daily_budget}
            onChange={handleChange}
            label="Daily budget"
            name="daily_budget"
            placeholder="00.00"
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            pb: 4,
            mx: 2,
          }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            // disabled={isLoading}
            variant="contained"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
