import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { createCampaign } from '../../utils/methods';

import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';

const initialForm = {
  keywords: '',
  keywordsCptBid: '',
};

export default function DeleteKeys({ editModal, handleClose, handleConfirm }) {
  const { open, deleteType, campaignId } = editModal;

  const onConfirm = () => {
    handleConfirm(deleteType, campaignId);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        Are you sure you want to delete adset?
      </DialogTitle>
      <Box component="form" autoComplete="off" sx={{ pt: 1 }}>
        <DialogActions
          sx={{
            pb: 4,
            mx: 2,
          }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" autoFocus onClick={onConfirm}>
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
