import { useEffect, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { visuallyHidden } from '@mui/utils';

import {
  DEFAULT_PAGINATION,
  headCells,
  CAMPAIGN_STATUS,
} from '../../utils/const';

import EditCampaign from './EditCampaign';
import Amount from '../components/AmountBadge';

import { useCampaigns } from '../../hooks/useCampaigns';

const initialModalParams = {
  type: '',
  open: false,
  campaign: {
    id: undefined,
    daily_budget: '',
  },
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.align
                ? headCell.align
                : headCell.numeric
                ? 'right'
                : 'left'
            }
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function CampaignsList({ rows, setRows }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('asa_name');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGINATION.PER_PAGE);
  const [editModal, setEditModal] = useState(initialModalParams);
  const [searchValue, setSearch] = useState('');
  const [initialRows, setInitialRows] = useState([]);

  const { isLoading, editCampaign, deleteCampaign } = useCampaigns();
  const navigate = useNavigate();

  useEffect(() => {
    setInitialRows(rows);
  }, []);

  const handleEditModal = (event, campaign) => {
    event.stopPropagation();
    setEditModal({ type: 'dailyBudget', open: true, campaign });
  };

  const handleCloseEdit = () => {
    setEditModal(initialModalParams);
  };

  const handlePlay = (event, campaignId) => {
    event.stopPropagation();
    const { name, checked } = event.target;

    editCampaign(campaignId, {
      [name]: checked ? CAMPAIGN_STATUS.ENABLED : CAMPAIGN_STATUS.PAUSED,
    });
  };

  const handleDelete = (event, type, campaignId) => {
    event.stopPropagation();
    deleteCampaign(campaignId);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event?.target?.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleStopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleRedirectClick = (event, campaignId) => {
    if (event.type === 'click') {
      navigate(`${campaignId}/adsets`);
    }
  };

  const handleSearch = (event) => {
    const { value } = event.target;

    if (value === '') {
      handleResetSearch();
    } else {
      setSearch(value);
      debounceSearch(value);
    }
  };

  const getSearch = (value) => {
    const filteredRows = initialRows.filter((row) => {
      return row.keyword?.toLowerCase().includes(value?.toLowerCase());
    });

    setRows(filteredRows);
  };

  const debounceSearch = useCallback(debounce(getSearch, 500), []);

  const handleResetSearch = () => {
    setSearch('');
    setRows(initialRows);
  };

  return (
    <>
      <Box sx={{ width: '100%', my: 5 }}>
        <Paper sx={{ width: '100%' }}>
          <Toolbar>
            <FormControl variant="outlined" sx={{ my: 2 }}>
              <InputLabel>Search</InputLabel>
              <OutlinedInput
                sx={{ width: '400px' }}
                label="Search"
                value={searchValue}
                onChange={handleSearch}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleResetSearch}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {searchValue ? <CloseIcon fontSize="small" /> : ''}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              stickyHeader
              aria-label="sticky table"
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    const {
                      id,
                      daily_budget,
                      avg_cpa,
                      avg_cpt,
                      impressions,
                      installs,
                      spend,
                      taps,
                      ttr,
                      status,
                    } = row;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={id}
                        selected={isItemSelected}
                        onClick={(e) => handleRedirectClick(e, row.id)}
                      >
                        <TableCell component="th" id={labelId} scope="row">
                          {row.asa_name}
                        </TableCell>
                        <TableCell>
                          {daily_budget && <Amount value={daily_budget} />}
                        </TableCell>

                        <TableCell>{avg_cpa}</TableCell>
                        <TableCell>{avg_cpt}</TableCell>
                        <TableCell>{impressions}</TableCell>
                        <TableCell>{installs}</TableCell>
                        <TableCell>{spend}</TableCell>
                        <TableCell>{taps}</TableCell>
                        <TableCell>{ttr}</TableCell>
                        <TableCell
                          sx={{
                            width: 200,
                          }}
                        >
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={(e) => handleEditModal(e, row)}
                          >
                            Edit daily budget
                          </Button>
                          <Box>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography>{CAMPAIGN_STATUS.PAUSED}</Typography>
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={isLoading}
                                    checked={status === CAMPAIGN_STATUS.ENABLED}
                                    onChange={(e) => handlePlay(e, row.id)}
                                    onClick={handleStopPropagation}
                                    name="status"
                                  />
                                }
                              />
                              <Typography>{CAMPAIGN_STATUS.ENABLED}</Typography>
                            </Stack>
                          </Box>
                          <Button
                            onClick={(e) => handleDelete(e, 'single', id)}
                            size="small"
                            variant="outlined"
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={DEFAULT_PAGINATION.PER_PAGE_OPTIONS}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
      {editModal.open && (
        <EditCampaign editModal={editModal} handleClose={handleCloseEdit} />
      )}
    </>
  );
}
