import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { getApps, syncApps } from '../../utils/methods';
import { useAuth } from '../../hooks/useAuth';

function AppItem({ item }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        filter: !item.asa ? 'grayscale(100%)' : 'none',
        cursor: !item.asa ? 'not-allowed' : 'inherit',
      }}
    >
      <img
        loading="lazy"
        width={64}
        src={item.icon}
        alt={item.id}
        className="app-icon"
      />

      <div>
        {item.name}
        {!item.asa && (
          <Typography variant="error" display="block" gutterBottom>
            App was removed by ASA
          </Typography>
        )}
      </div>
    </Box>
  );
}

function AppsList({ apps }) {
  return apps.length ? (
    apps.map((i) => (
      <Grid key={i.id} item xs={2} sm={4} md={4} sx={{ mb: 5 }}>
        {i.asa ? (
          <Link to={`${i.id}/campaigns`}>
            <AppItem item={i} />
          </Link>
        ) : (
          <AppItem item={i} />
        )}
      </Grid>
    ))
  ) : (
    <Typography variant="button" display="block" gutterBottom>
      No apps
    </Typography>
  );
}

export default function Apps() {
  const [apps, setApps] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const { selectedUser } = useAuth();

  useEffect(() => {
    getList();
  }, [selectedUser]);

  const getList = () => {
    return getApps(selectedUser?.id)
      .then((res) => {
        setApps(res.data.apps);
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const syncList = () => {
    setLoading(true);
    return syncApps(selectedUser?.id)
      .then((res) => {
        setApps(res.data.apps);
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={syncList}
        disabled={isLoading}
        variant="contained"
        endIcon={<SyncIcon />}
        sx={{
          display: 'flex',
          ml: 'auto',
          mr: 0,
          mb: 5,
        }}
      >
        Sync apps
      </Button>
      {isLoading ? (
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {Array.from(Array(8)).map((_, index) => (
            <Grid
              item
              xs={2}
              sm={4}
              md={4}
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Skeleton
                variant="rectangular"
                width={64}
                height={64}
                sx={{
                  m: 2,
                }}
              />
              <Skeleton variant="rectangular" width={64} height={24} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Grid
            container
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 2, sm: 8, md: 12 }}
          >
            <AppsList apps={apps} />
          </Grid>
        </>
      )}
    </>
  );
}
