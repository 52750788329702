
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSets } from '../../hooks/useSets';

export default function BidManagment({ editModal, handleClose }) {
  const { open, bid } = editModal;


  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState(bid);

  const { editNegativeList } = useSets();

  const handleChange = (e) => {
    const { value } = e.target;
    setForm(value);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setLoading(true);

    editNegativeList(
      form.split(/\r?\n/).filter((i) => i),
      handleClose
    );
    setLoading(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>Bid managment</DialogTitle>
      <Box
        component="form"
        autoComplete="off"
        onSubmit={handleEdit}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            required
            rows={4}
            label="Negative keywords"
            multiline
            name="negativeKeywords"
            placeholder="Maximum 20 keywords"
            value={form}
            onChange={(e) => handleChange(e, true)}
          />
        </DialogContent>
        <DialogActions
          sx={{
            pb: 4,
            mx: 2,
          }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            disabled={isLoading}
            variant="contained"
            autoFocus
          >
            Edit
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
