import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';
import { useAuth } from '../../hooks/useAuth';
import { HOME_ROUTE } from '../../utils/const';

export default function NotAuthLayout() {
  const { signed, logout } = useAuth();

  // if (signed) {
  //   return <Navigate to={HOME_ROUTE} replace />;
  // }

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Outlet />
    </Container>
  );
}
