import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import { getClients } from '../utils/methods';
import { HOME_ROUTE } from '../utils/const';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage('token', '');
  const [isLoading, setLoading] = useState(true);

  const [clients, setClients] = useState([]);
  const [selectedUser, setSelectedUser] = useLocalStorage('user', {});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getClients();
      setLoading(false);

      setClients(res?.data);
      if (!selectedUser?.id) {
        setSelectedUser(res?.data[0]);
      }
    };

    fetchData().catch((e) => {});
  }, []);

  const login = (hash) => {
    setToken(hash);

    getClients()
      .then((res) => {
        setClients(res?.data);

        if (!selectedUser?.id) {
          setSelectedUser(res?.data[0]);
        }
        navigate(HOME_ROUTE);
      })
      .finally(() => setLoading(false));
  };

  const logout = () => {
    setToken('');
  };

  return (
    <AuthContext.Provider
      value={{
        signed: !!token,
        token,
        isLoading,
        login,
        logout,
        clients,
        setClients,
        selectedUser,
        setSelectedUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
