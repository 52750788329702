import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';
import { API_ENDPOINT } from '../utils/envs';

const instance = axios.create({
  baseURL: API_ENDPOINT,
});

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();

  // const token = localStorage.getItem('token');
  const { token, logout } = useAuth();

  useEffect(() => {
    instance.interceptors.request.use(
      async (config) => {
        if (!!token) {
          config.headers = {
            Authorization: `Basic ${token}`,
          };
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      if (error?.response?.status === 401) {
        logout();
        navigate('/login');

        // return navigate('/login', {replace: true});
      } else if (error?.response?.status === 422) {
        Object.keys(error?.response.data).map((key) =>
          toast.error(`💩 ${key}: ${error?.response.data[key]}`)
        );
      } else {
        toast.error('💩 ' + error.message);
      }

      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => instance.interceptors.response.eject(interceptor);
  }, [token, navigate]);

  return children;
};

export default instance;
export { AxiosInterceptor };
