import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import { useAuth } from '../hooks/useAuth';

export default function Login() {
  const [form, setForm] = useState({ email: '', password: '' });
  const { email, password } = form;

  const { login } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login(btoa(`${email}:${password}`));
  };

  return (
    <Paper
      elevation={3}
      variant="elevation"
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 500,
        p: 4,
        mx: 'auto',
      }}
    >
      <TextField
        required
        value={email}
        onChange={handleChange}
        label="Email"
        name="email"
      />
      <TextField
        required
        value={password}
        onChange={handleChange}
        name="password"
        label="Password"
        type="password"
        autoComplete="current-password"
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Sign In
      </Button>
    </Paper>
  );
}
