import AddKeys from './AddKeys';
import EditNegativeKeys from './EditNegativeKeys';
import DeleteKeys from './DeleteKeys';
import BidManagment from './BidManagment';

const TITLES = {
  addKeywords: 'addKeywords',
  editNegativeKeywords: 'editNegativeKeywords',
  bidManagment: 'bidManagment',
  delete: 'delete'
};


export default function Modals(props) {
  const { type } = props.editModal;

  return (
    <>
      {type === TITLES.addKeywords && <AddKeys {...props} />}
      {type === TITLES.editNegativeKeywords && <EditNegativeKeys {...props} />}
      {type === TITLES.delete && <DeleteKeys {...props} />}
      {type === TITLES.bidManagment && <BidManagment {...props} />}
    </>
  );
}
