import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CampaignsList from './CampaignsList';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';

import { useCampaigns } from '../../hooks/useCampaigns';
import { getCampaigns } from '../../utils/methods';

export default function Campaigns() {
  // const [appName, setAppName] = useState('');
  // const [isLoading, setLoading] = useState(false);
  // const [campaigns, setCampaigns] = useState([]);

  const navigate = useNavigate();
  const { isLoading, campaigns, getCampaigns, appName, setCampaigns } =
    useCampaigns();
  // let { app_id } = useParams();

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width={500} height={40} />
      ) : (
        <Typography variant="h4" component="div">
          {appName}
        </Typography>
      )}

      <Button variant="outlined" sx={{ mt: 2 }} onClick={() => navigate('new')}>
        Create campaign
      </Button>
      {isLoading ? (
        <CircularProgress color="info" size={60} />
      ) : (
        <CampaignsList rows={campaigns} setRows={setCampaigns} />
      )}
    </>
  );
}
