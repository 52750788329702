import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useAuth } from '../../hooks/useAuth';
import { useCampaigns } from '../../hooks/useCampaigns';
import { useSets } from '../../hooks/useSets';

export default function BreadcrumbsComponent() {
  const { pathname } = useLocation();
  const { selectedUser } = useAuth();
  const { appName } = useCampaigns();
  const { campaignName, appName: appNameFromSets } = useSets();

  const ROUTES = [
    {
      title: 'Apps',
      path: 'apps',
      pattern: /apps$/,
      breadcrumbs: [{ name: 'Apps', path: '' }],
    },
    {
      title: 'Campaigns',
      path: 'apps/:app_id/campaigns',
      pattern: /apps\/[0-9]+\/campaigns$/,
      breadcrumbs: [
        { name: 'Apps', path: '/apps' },
        { name: appName, path: '' },
        { name: 'Campaigns', path: '' },
      ],
    },
    {
      title: 'Create campaign',
      path: 'apps/:app_id/campaigns/new',
      pattern: /apps\/[0-9]+\/campaigns\/new/,
      breadcrumbs: [
        { name: 'Apps', path: '/apps' },
        { name: appName, path: '' },
        { name: 'Campaigns', path: '..' },
        { name: 'Create campaign', path: '' },
      ],
    },
    {
      title: 'Adsets',
      path: 'apps/:app_id/campaigns/:campaign_id/adsets',
      pattern: /apps\/[0-9]+\/campaigns\/[0-9]+\/adsets/,
      breadcrumbs: [
        { name: 'Apps', path: '/apps' },
        { name: appNameFromSets, path: '' },
        { name: 'Campaigns', path: '../..' },
        { name: campaignName, path: '' },
        { name: 'Adsets', path: '' },
      ],
    },
  ];

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const needShowUsername = true;
  // const needShowUsername = pathname !== '/apps' && pathname !== '/apps/';

  useEffect(() => {
    const list = ROUTES.find((i) => i.pattern?.test(pathname))?.breadcrumbs;
    setBreadcrumbs(list);

    document.title =
      ROUTES.find((i) => i.pattern?.test(pathname))?.title || 'ASA FAST';
  }, [pathname, appName, campaignName]);

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={{ mb: 5 }}
      maxItems={4}
    >
      {needShowUsername && (
        <Typography color="secondary.dark">{selectedUser?.name}</Typography>
      )}
      {breadcrumbs &&
        breadcrumbs.map((i) => {
          return i.path ? (
            <Link
              key={i.name}
              to={i.path}
              relative="path"
              className=" MuiLink-root MuiLink-underlineNone"
            >
              {i.name}
            </Link>
          ) : (
            <Typography color="secondary.dark" key={i.name}>
              {i.name}
            </Typography>
          );
        })}
    </Breadcrumbs>
  );
}
